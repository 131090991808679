import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FaEnvelope, FaWhatsapp, FaInstagram, FaLinkedin, FaAndroid, FaApple } from 'react-icons/fa'; // Importing icons
import logo from './brandmark-white.png';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Gilroy', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    color: #333;
  }
`;

const AppContainer = styled.div`
  text-align: center;
`;

const Logo = styled(motion.img)`
  height: 80px;
  margin: 20px 0;
`;

const Header = styled.header`
  background-color: #0a64ff;
  color: white;
  padding: 20px;
`;

const Section = styled(motion.section)`
  background-color: white;
  margin: 20px auto;
  padding: 20px;
  max-width: 800px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h2 {
    color: #0648b8;
  }
`;

const List = styled.ul`
  text-align: left;
  padding-left: 20px;
`;

const ListItem = styled.li`
  margin: 10px 0;
`;

const StyledLink = styled.a`
  color: #0648b8;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Button = styled.a`
  display: inline-block;
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #0648b8;
  background-color: white;
  border-radius: 5px;
  text-decoration: none;

  &:hover {
    background-color: #0648b8;
    color: white
  }
`;

const SocialLink = styled.a`
  display: inline-block;
  margin: 0 10px;
  color: #0648b8;
  font-size: 24px;
  text-decoration: none;

  &:hover {
    color: #043a91;
  }
`;

const spinTransition = {
  loop: Infinity,
  ease: "linear",
  duration: 5,
};

function App() {
  return (
    <AppContainer>
      <Helmet>
        <title>DivMeds</title>
        <meta name="description" content="DivMeds: Revolutionizing the Indian Healthcare Ecosystem with comprehensive networking, learning, and recruitment tools for healthcare professionals and patients." />
        <meta name="keywords" content="DivMeds, healthcare, networking, EHR, recruitment, learning, healthcare professionals, patients, India" />
        <meta property="og:title" content="DivMeds" />
        <meta property="og:description" content="Revolutionizing the Indian Healthcare Ecosystem with comprehensive networking, learning, and recruitment tools for healthcare professionals and patients." />
        <meta property="og:image" content="%PUBLIC_URL%/brandmark-blue.png" />
        <meta property="og:url" content="http://www.divmeds.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="DivMeds" />
        <meta name="twitter:description" content="Revolutionizing the Indian Healthcare Ecosystem with comprehensive networking, learning, and recruitment tools for healthcare professionals and patients." />
        <meta name="twitter:image" content="%PUBLIC_URL%/brandmark-blue.png" />
        <meta name="twitter:site" content="@DivMeds" />
      </Helmet>
      <Header>
        <Logo
          src={logo}
          alt="DivMeds Logo: Revolutionizing the Indian Healthcare Ecosystem"
          animate={{ rotate: 360 }}
          transition={spinTransition}
        />
        <motion.h1 initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          DivMeds: Revolutionizing the Indian Healthcare Ecosystem
        </motion.h1>
      </Header>

      <Section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5 }}>
        <h2>Overview</h2>
        <p>DivMeds is a comprehensive, specialized platform designed to address various challenges faced by healthcare professionals, students, employers, and patients. It aims to streamline networking, knowledge sharing, recruitment, and access to healthcare resources within the healthcare industry.</p>
      </Section>

      <Section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1 }}>
        <h2>Key Features</h2>
        <List>
          <ListItem><strong>Networking for Healthcare Professionals:</strong> Dedicated space for healthcare professionals and students to connect.</ListItem>
          <ListItem><strong>Continuous Learning and Knowledge Sharing:</strong> Gamified learning modules, video tutorials, 3D diagrams, quizzes, and subject notes.</ListItem>
          <ListItem><strong>Job Searching and Recruitment:</strong> Tools for job seekers to find relevant opportunities.</ListItem>
          <ListItem><strong>Unified Electronic Health Records (EHR):</strong> Integrates patient data into a single, unified system.</ListItem>
          <ListItem><strong>Patient Engagement and Access:</strong> Patient portals for accessing medical records, scheduling appointments, and communication with healthcare providers.</ListItem>
          <ListItem><strong>Healthcare Resource Directory:</strong> Centralized directory of drugs, medicines, and healthcare professionals.</ListItem>
          <ListItem><strong>Professional Branding:</strong> Detailed profiles showcasing qualifications, skills, and achievements.</ListItem>
          <ListItem><strong>Endorsements and Peer Validation:</strong> Platform for endorsing skills and writing recommendations.</ListItem>
          <ListItem><strong>Healthcare Business Development:</strong> Opportunities for healthcare businesses to create profiles and promote services.</ListItem>
          <ListItem><strong>Industry News and Updates:</strong> Hub for healthcare news and updates.</ListItem>
          <ListItem><strong>Advanced Recruitment Tools:</strong> Specialized recruitment solutions for healthcare employers.</ListItem>
        </List>
      </Section>

      <Section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.5 }}>
        <h2>DivMeds Aims to Solve</h2>
        <List>
          <ListItem><strong>Fragmented Professional Networking:</strong> A dedicated platform for healthcare networking.</ListItem>
          <ListItem><strong>Limited Access to Learning Resources:</strong> Specialized learning modules and resources.</ListItem>
          <ListItem><strong>Inefficient Job Searching and Recruitment:</strong> Streamlined job search and recruitment tools.</ListItem>
          <ListItem><strong>Fragmented Patient Health Information:</strong> Unified EHR for comprehensive patient records.</ListItem>
          <ListItem><strong>Limited Patient Engagement:</strong> Patient portals for better engagement and care management.</ListItem>
          <ListItem><strong>Lack of Centralized Healthcare Directory:</strong> Reliable directory of drugs-medicines, and healthcare professionals.</ListItem>
          {/* <ListItem><strong>Professional Branding and Validation:</strong> Detailed profiles and endorsement system.</ListItem> */}
          <ListItem><strong>Healthcare Business Development Challenges:</strong> Tools for business promotion and connections.</ListItem>
        </List>
      </Section>

      <Section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 2 }}>
        <h2>Contact DivMeds</h2>
        <p>
          <SocialLink href="mailto:divmeds0@gmail.com" target="_blank" rel="noopener noreferrer"><FaEnvelope /></SocialLink>
          <SocialLink href="https://wa.me/918001985741" target="_blank" rel="noopener noreferrer"><FaWhatsapp /></SocialLink>
          <SocialLink href="https://www.instagram.com/divmeds" target="_blank" rel="noopener noreferrer"><FaInstagram /></SocialLink>
          <SocialLink href="https://www.linkedin.com/company/divmeds" target="_blank" rel="noopener noreferrer"><FaLinkedin /></SocialLink>
        </p>
      </Section>

      <Section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 2.5 }}>
        <h2>Download DivMeds</h2>
        <Button href="https://play.google.com/store/apps/details?id=com.divmeds" target="_blank" rel="noopener noreferrer"><FaAndroid /></Button>
        <Button href="https://apps.apple.com/us/app/divmeds/id1234567890" target="_blank" rel="noopener noreferrer"><FaApple /> </Button>
      </Section>
    </AppContainer>
  );
}

export default App;
